import consumer from "./consumer";
import * as timeago from 'timeago.js';

function time(date){

}
document.addEventListener("turbolinks:load", function(event) {
  var paramsId = document.querySelector('[data-params-id]');
  var paginate = {};
  var next, requeted;
  var receivedMsg = []
  if(paramsId){
    var msgBox = $('[data-params-id]');
    var ice = document.querySelector("meta[name='ice']").getAttribute("content")
    var dataId = paramsId.getAttribute('data-params-id')
    let Room = consumer.subscriptions.create({channel: "MessageChannel", id: dataId}, {
      connected() {
        if(receivedMsg.length) {
          requeted = true
					this.perform('load_screen', {id: dataId, last: receivedMsg.pop().iden	})
				}else{
          requeted = true
					this.perform('load_screen', {id: dataId})
				}
      },
      disconnected() {
      },
      sendMessage(data){
				this.perform("create_message",{id: dataId, message: data})
			},
      received(data) {
        if (data.message instanceof Array) {
          this.show_array(data)
				}else{
          this.show_message(data.message, data.paginate)
				}
        next = '';
        rendr()
      },
      next_page(page){
        requeted = true
        if(page !== null) this.perform("load_chats",{id: dataId,page: page})
      },
      show_message(data, page){
        var iden = data.iden
        if(!receivedMsg.includes(iden)){
          if(page) paginate = page
          receivedMsg.push(data.iden)
          if (data.type != ice){
            if(paginate.current_page > 1 && next){
              if(paginate.current_page == next){
                
                msgBox.prepend( this.reciever(data))
              }
            }else{
              msgBox.append( this.reciever(data))
              paramsId.scrollTop = paramsId.scrollHeight
            }
            if (!document.hidden){
              this.perform("touch",{msg: data.msg})
            }
          }else{
            if(paginate.current_page > 1 && next){
              if(paginate.current_page == next){
                msgBox.prepend( this.sender(data))
              }
            }else{
              msgBox.append( this.sender(data))
              paramsId.scrollTop = paramsId.scrollHeight
            }
            if (!document.hidden){
              this.perform("touch",{msg: data.msg})
            }
          }
        }
      },
      show_array(data){
        if(requeted){
          requeted = false
          data.message.map((message)=>{
            this.show_message(message, data.paginate)
          })
        }
      },
      reciever(data){
       return(
         `<div class='sent-msg'>
            <img width='35' height='35' src='${data.reciever_avatar}' crop='fill'  class='msg-avatar'/>
            <div class='msg-bubble'>
              <span>${data.message}</span>

              <p class='msg-timestamp' datetime='${data.time}'>${timeago.format(data.time)}</p>
            </div>
          </div>`
        )
      },
      sender(data){
        return(
          `<div class="recieve-msg">
            <img width='35' height='35' src='${data.sender_avatar}' crop='fill'  class='msg-avatar'/>
            <div class="msg-bubble">
              <span>${data.message}</span>
              <p class="msg-timestamp" datetime='${data.time}'>${timeago.format(data.time)}</p>
            </div>
          </div>`
        )
      }

    });
    $('.chat-messages-section').on('scroll', function(e){
      var p = $('.chat-messages-section').scrollTop()
      if(p < 200 && paginate.next_page){
        if(next !== paginate.next_page){
          next = paginate.next_page
          Room.next_page(paginate.next_page)
        }
      }

    })
function rendr(){
  timeago.render(document.querySelectorAll('.msg-timestamp'));

}
    $(document).on('click', '#message .text-end',function(event){
      var value = $('#message textarea').val()
      if(value && value.length) {
        Room.sendMessage({body: value})
      }
      document.getElementById('input-data').innerHTML = '';
      document.getElementById('post_content').value = '';
      event.preventDefault()
    })

  }
})