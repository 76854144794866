
function setGoogle(){
  if(window.google){
    initMap();
  } else{
    $.ajax('https://maps.googleapis.com/maps/api/js?key=AIzaSyDRW1rBJDsyB4WZiMvLQPV3LfCxR8oDzTE&callback=initMap&libraries=places&v=weekly&channel=2', {
      crossDomain: true,
      dataType: 'script'
    });
  }
}
function setGoogleservie(){
  if(window.google){
    initService();
  } else {
    $.ajax('https://maps.googleapis.com/maps/api/js?key=AIzaSyDRW1rBJDsyB4WZiMvLQPV3LfCxR8oDzTE&callback=initService&libraries=places&v=weekly&channel=2', {
      crossDomain: true,
      dataType: 'script'
    });
  }
}

export {setGoogle, setGoogleservie};
