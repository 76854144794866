var endinput, endtime, endtimediv;
var startinput, starttime, starttimediv;
var startDivBox, endDivBox;
var moment = require('moment');
window.moment = moment;
function setInputs() {
  endinput = document.getElementById('checkout');
  endtime = document.getElementById('checkoutTime');
  endtimediv = document.getElementById('checkout-time-picker');
  startinput = document.getElementById('checkin');
  starttime = document.getElementById('checkinTime');
  starttimediv = document.getElementById('checkin-time-picker');
  startDivBox = document.getElementById('table-div')
  endDivBox = document.getElementById('table-div1')
}

function setDate(date){
  return moment(date, 'DD/MM/YYYY').toDate()
  // moment(currentDate).add(-1, 'M').toDate();
}
function setDateTime(date){
  return moment(date, 'DD/MM/YYYY HH:mm').toDate()
}
function setDateMonth(currentDate , month){
  return moment(currentDate).add(month, 'M').toDate();
}
function startOfMonth(date){
  return moment(date).startOf('month').toDate()
}
function endOfMonth(date){
  return moment(date).endOf('month').toDate()
}
function startofweek(date){
  return moment(date).startOf('isoweek').toDate()
}

function endofweek(date){
  return moment(date).endOf('isoweek').toDate()
}

function dateViewAble(date){
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}
function enumerateDaysBetweenDates(startDate, endDate) {
  startDate = moment(startDate);
  endDate = moment(endDate);

  var now = startDate, dates = [];

  while (now.isBefore(endDate) || now.isSame(endDate)) {
      dates.push(now.toDate());
      now.add(1, 'days');
  }
  return dates;
};

function setCalander(tableID, dateInput, type){
  setInputs()
  var prevMonth = document.querySelector(`#${tableID} #prevMonth`)
  var prevNext = document.querySelector(`#${tableID} #prevNext`)
  var prevCurrent = document.querySelector(`#${tableID} #monthAndYear`) 
  var prevMonth2 = document.querySelector(`#table1 #prevMonth`)
  var prevNext2 = document.querySelector(`#table1 #prevNext`)
  var prevCurrent2 = document.querySelector(`#table1 #monthAndYear`) 
  var today = setDate(prevCurrent.dataset.month);
  searchCalander(tableID, dateInput, type, today)
  prevMonth.onclick = prev
  function prev(){
    var dateCmp = startOfMonth(new Date())
    if (dateCmp >= setDateMonth(today, -1)) prevMonth.classList.add('t-disabled')
    if (dateCmp <= setDateMonth(today, -1)){
      today = setDate(prevMonth.dataset.month);
      prevMonth.setAttribute('data-month', dateViewAble(setDateMonth(today, -1)))
      prevNext.setAttribute('data-month', dateViewAble(setDateMonth(today, 1)))
      prevCurrent.setAttribute('data-month', dateViewAble(today))
      prevMonth2.setAttribute('data-month',  dateViewAble(setDateMonth(today, -1)))
      prevNext2.setAttribute('data-month', dateViewAble(setDateMonth(today, 1)))
      prevCurrent2.setAttribute('data-month', dateViewAble(today))
      searchCalander(tableID, dateInput, type, today)
    }
  }
  const next = () => {
    today = setDate(prevNext.dataset.month);
    setdates()
  }
  prevNext.onclick = next
  function setdates(){
    prevMonth.classList.remove('t-disabled')
    prevMonth.setAttribute('data-month',  dateViewAble(setDateMonth(today, -1)))
    prevNext.setAttribute('data-month', dateViewAble(setDateMonth(today, 1)))
    prevCurrent.setAttribute('data-month', dateViewAble(today))
    searchCalander(tableID, dateInput, type, today)
    prevMonth2.setAttribute('data-month',  dateViewAble(setDateMonth(today, -1)))
    prevNext2.setAttribute('data-month', dateViewAble(setDateMonth(today, 1)))
    prevCurrent2.setAttribute('data-month', dateViewAble(today))
  }
}

function searchCalander(table, input, type, today) {

  if (type == 'start'){
    starttime.removeEventListener('change' , addEventListenerChanges)
    starttime.addEventListener('change' , addEventListenerChanges)
  }
  if (type == 'end'){
    endtime.removeEventListener('change' , addEventListenerChanges)
    endtime.addEventListener('change' , addEventListenerChanges)
  }
  if(document.cookie === 'lng=en'){
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]; 
  }else{
    var months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  }
	var monthAndYear = document.querySelector(`#${table} #monthAndYear`);
	var showCalendar = function(month, year) {
    let firstDayTheMonth = startOfMonth(today)
    let lastDateofTheMonth = endOfMonth(today)
    var startWeek = startofweek(firstDayTheMonth)
    var endWeek = endofweek(lastDateofTheMonth)
    var totalDates = enumerateDaysBetweenDates(startWeek, endWeek)
    var tbl = document.querySelector(`#${table} #calendar-body`); // body of the calendar
    tbl.innerHTML = "";
    monthAndYear.innerHTML = `${months[month]} ${year}`;
    let date = startWeek;
    let row = '';
		for (let i = 0; i < totalDates.length; i++) {
			if(date.getDay() == 1) row = document.createElement("tr");
      if (totalDates[i] < firstDayTheMonth || totalDates[i] > lastDateofTheMonth) {
        var cell = document.createElement("td");
        cell.classList.add('t-disabled')
        cell.setAttribute('data-date', dateViewAble(date))
        var cellText = document.createTextNode(date.getDate());
        cell.appendChild(cellText);
        row.appendChild(cell);
      }else {
        var cell = document.createElement("td");
        cell.setAttribute('data-date', dateViewAble(date))
        var cellText = document.createTextNode(date.getDate());
        if(dateViewAble(date) === startinput.value){
          cell.classList.add('t-selected-first')
          if (!endinput.value.length) cell.classList.add('t-selected-last')
        }
        if(dateViewAble(date) === endinput.value){
          cell.classList.add('t-selected-last')
          if (!startinput.value.length) cell.classList.add('t-selected-first')
        }
        var date1 = date
        if(new Date().setHours(0,0,0,0) > date1.setHours(0,0,0,0)){
          cell.classList.add('t-disabled')
        }
        if (type == 'end' && startinput) {
          if (date1.setHours  (0,0,0,0) < setDate(startinput.value).setHours(0,0,0,0)){
            cell.classList.add('t-disabled')
          }
        } 
        if (type == 'start' && endinput) {
          if (date1.setHours(0,0,0,0) > setDate(endinput.value).setHours(0,0,0,0)){
            cell.classList.add('t-disabled')
          }
        } 
        if (startinput.value && endinput.value){
          if (date1.setHours(0,0,0,0) > setDate(startinput.value).setHours(0,0,0,0) && date1.setHours(0,0,0,0) < setDate(endinput.value).setHours(0,0,0,0)){
            if(dateViewAble(date) === startinput.value){
              cell.classList.add('t-selected-first')
            }else if(dateViewAble(date) === endinput.value){
              cell.classList.add('t-selected-last')
            }
            cell.classList.add('t-selected')
          }
        }
        cell.appendChild(cellText);
        row.appendChild(cell);
      }
      date = new Date(date.getTime() + 86400000);
      if(totalDates[i].getDay() == 1) tbl.appendChild(row); 
    }
    selectdate(table)
	}
	showCalendar(today.getMonth(), today.getFullYear());
 
  
 
  function tables(event){
    selecterSetup('table', 'start', event)
    selecterSetup('table1', 'end', event)
  }

  function selectdate(table){
    document.querySelectorAll(`#${table} td`).forEach(td => {
      td.onclick = function(event){
      var td = event.target
      if(td.classList.contains('t-selected-last','t-selected-first')){
        // td.classList.remove('t-selected-last','t-selected-first')
        // var previous = td.previousElementSibling
        // if(previous){
        //   if(previous.classList.contains('t-selected') || previous.classList.contains('t-selected-first')){
        //     previous.classList.remove('t-selected')
        //     previous.classList.add('t-selected-last')

        //   }
        // }   
        // if (document.getElementById('table').contains(td)){
        //   startinput.value = ''
        // }else{
        //   endinput.value = ''
        // }
      }else{
        if(!event.target.classList.contains('t-disabled')){
          tables(event)
        }
      }

      } 
    })
  }


  function selecterSetup(table, type, event){
    var td = event.target;
    if (document.getElementById('table').contains(td)){
      startinput.value = td.getAttribute('data-date')
    }else{
      endinput.value = td.getAttribute('data-date')
    }
    document.querySelectorAll(`#${table} td`).forEach(t => {
      t.classList.remove('t-selected-last')
      t.classList.remove('t-selected-first')
      t.classList.remove('t-selected')
      if (setDate(startinput.value).setHours(0,0,0,0) <= setDate(t.getAttribute('data-date')).setHours(0,0,0,0)
      && setDate(endinput.value).setHours(0,0,0,0) >= setDate(t.getAttribute('data-date')).setHours(0,0,0,0)){
        t.classList.remove('t-disabled')
      }
      if(t.getAttribute('data-date') == startinput.value){
        t.classList.add('t-selected-first') 
        if(t.getAttribute('data-date') == endinput.value || !endinput.value) t.classList.add('t-selected-last') 
      } 
      if(t.getAttribute('data-date') == endinput.value){
        t.classList.add('t-selected-last')
        if(t.getAttribute('data-date') == startinput.value || !startinput.value) t.classList.add('t-selected-first') 
      }
      if (startinput.value && endinput.value){
        if (
          setDate(startinput.value).setHours(0,0,0,0) < setDate(t.getAttribute('data-date')).setHours(0,0,0,0) 
          && setDate(endinput.value).setHours(0,0,0,0) > setDate(t.getAttribute('data-date')).setHours(0,0,0,0)){
          t.classList.add('t-selected')
        }
      }
      if (type == 'end' && startinput) {
        if (setDate(t.getAttribute('data-date')).setHours(0,0,0,0) < setDate(startinput.value).setHours(0,0,0,0)){
          t.classList.add('t-disabled')
        }
      } 
      if (type == 'start' && endinput) {
        if (setDate(t.getAttribute('data-date')).setHours(0,0,0,0) > setDate(endinput.value).setHours(0,0,0,0)){
          t.classList.add('t-disabled')
        }
      } 
    })
    
    addEventListenerChanges()
  }
  function addEventListenerChanges(){
    if (endinput && endtime && startinput && starttime){
      for (const child of starttimediv.children) {
        if (endtime.value && setDateTime(`${startinput.value} ${child.getAttribute('data-value')}`) >= setDateTime(`${endinput.value} ${endtime.value}`)){
          child.classList.add('t-disabled')
          if (starttime.value == child.getAttribute('data-value')) starttime.value = ''
        }else{
          child.classList.remove('t-disabled')
        }
      }
      for (const child of endtimediv.children) {
        if (starttime.value && setDateTime(`${endinput.value} ${child.getAttribute('data-value')}`) <= setDateTime(`${startinput.value} ${starttime.value}`)){
          child.classList.add('t-disabled')
          if (endinput.value == child.getAttribute('data-value')) endtime.value = ''
        }else{
          child.classList.remove('t-disabled')
        }
      }
    }
  }

  starttimediv.addEventListener('click', setStartDivBox)
  endtimediv.addEventListener('click', setEndDivBox)
  function setStartDivBox(event){
    var target = event.target
    if (target.classList.contains('selected') && !target.classList.contains('infocus') && !target.classList.contains('outfocus') ){
      if (startinput.value.length && starttime.value.length) {
        startDivBox.classList.add('d-none')
        $('.t-selected-first, .t-selected-last').removeClass('t-selected-first t-selected-last')

        if (!endinput.value.length || !endtime.value.length) endDivBox.classList.remove('d-none')
      }
    }
  }

  function setEndDivBox(event){
    var target = event.target
    if (target.classList.contains('selected') && !target.classList.contains('infocus') && !target.classList.contains('outfocus') ){
      if (endinput.value && endtime.value){
        endDivBox.classList.add('d-none')
        $('.t-selected-first, .t-selected-last').removeClass('t-selected-first t-selected-last')
        if (!startinput.value.length || !starttime.value.length) startDivBox.classList.remove('d-none')
      }
    }
  }

}

export default setCalander ;
