// window.addEventListener('resize', function(){
//     setCalander()
// });
// window.addEventListener('load', function(){
//     setCalander()
// })

// var setCalander = () => {
//     var ele = document.getElementsByClassName('t-datepicker-day')
//     var wrap1 = document.getElementById('t-table-wrap-1')
//     var wrap2 = document.getElementById('t-table-wrap-2')
//     var wrap3 = document.getElementById('t-table-wrap-3')
//     if (ele.length){
//         if (screen.width < 767) {
//             ele[0].classList.remove('t-datepicker-days')
//             wrap1.classList.add('d-none')
//             wrap2.classList.add('d-none')
//             wrap3.classList.add('d-none')
//         }else{
//             ele[0].classList.add('t-datepicker-days')
//             wrap1.classList.remove('d-none')
//             wrap2.classList.remove('d-none')
//             wrap3.classList.remove('d-none')
//         }
//     }
// }
