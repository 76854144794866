// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import Toastr from "toastr";
import {myFunction, mynewFunction, openSidebar, setCookie} from './mifleet.js';
import Calendar from './calendar';
import MobileScrollSelect from './MobileSelect';
import setCalander from './searchCalander';
import initMap from './places-autocomplete';
import {setGoogle, setGoogleservie} from './setGoogle';
import initService from './initService';
Rails.start()
Turbolinks.start()
ActiveStorage.start()
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../stylesheets/application.css';

require('bootstrap')
require('./responsiv')

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
window.myFunction = myFunction
window.mynewFunction = mynewFunction
window.openSidebar = openSidebar
window.toastr = Toastr
window.Calendar = Calendar
window.MobileScrollSelect = MobileScrollSelect
window.setCalander = setCalander
window.initMap = initMap
window.setGoogle = setGoogle
window.setGoogleservie = setGoogleservie
window.initService = initService
window.setCookie = setCookie