import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import ionRangeSlider from 'ion-rangeslider';
import "slick-carousel/slick/slick.min.js";

const myFunction = () => {
  var x = document.getElementById("nav");
  if (x.style.display === "none") {
    if(window.screen.width > 767){
      x.style.display = "flex";
    }else{
      x.style.display = "block";
    }
  } else {
    x.style.display = "none";
  }
}


function bannerSlider(){
  $(".banner-slider").slick({
    centerMode: true,
    centerPadding: "60px",
    slidesToShow: 2,
    variableWidth: true,
    prevArrow: $(".prev"),
    nextArrow: $(".next"),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          centerMode: true,
          centerPadding: "0",
          slidesToShow: 1,
          variableWidth: false
        },
      },
    ],
  });
}

function mynewFunction() {
  $("#filters-all").fadeToggle();
  if (screen.width > 992) {
    $("body").removeClass("overflow");
  }
  $("body").toggleClass("overflow");
}



// tabs click function

function openSidebar(open = false, e = '') {
  if (!open){
    if (screen.width < 993) {
      var tablinks = $(".tablinks");
      tablinks.addClass('d-none')
      $('.discription-banner').addClass('d-none')
      $('.discription-cardoorsopen').addClass('d-none')
    }else{
      $(".tablinks").removeClass("d-none");
      $('.discription-banner').removeClass('d-none')
      $('.discription-cardoorsopen').removeClass('d-none')
    }
  }else{
    e.preventDefault()
    if (screen.width < 993) {
      if ($(".tablinks").hasClass('d-none')){
        $(".tablinks").removeClass("d-none");
        $('.discription-banner').removeClass('d-none')
        $('.discription-cardoorsopen').removeClass('d-none')
      }else{
        $(".tablinks").addClass("d-none");
        $('.discription-banner').addClass('d-none')
        $('.discription-cardoorsopen').addClass('d-none')
      }
    }else{
      $(".tablinks").removeClass("d-none");
      $('.discription-banner').removeClass('d-none')
      $('.discription-cardoorsopen').removeClass('d-none')
    }
  }
  $(".tablinks.active").removeClass("d-none");
  $(".tablinks.active").addClass("d-flex");
}
window.addEventListener('resize', function(){
 openSidebar()
});

function myfunctionc(){
  // if('#unlock')
  var request = false;
  $('#unlock, #lock, #lock-l, #unlock-u').click(function(e){
		if(!request){
			var text = e.currentTarget.textContent
			$(e.currentTarget).html(`<i class="fa fa-refresh fa-spin me-1"></i> ${text}`)
			request = true;
			$.ajax({
				url: e.currentTarget.dataset.url,
				dataType: 'script',
				success: function(result){
					request = false
					console.log('hahahah')
					$(e.currentTarget).html(text)
				},
				error: function (xhr, status, error) {
					request = false;
					$(e.currentTarget).html(text);
					alert('check your internet connection and try again');
				}
			});
		}
	})
}

document.addEventListener("turbolinks:load", function(event) {
  openSidebar()
  pagination()
  bannerSlider()
  myfunctionc()
  $(".close-btn").on("click", function () {
    $("#filters-all").fadeOut();
    $("body").removeClass("overflow");
  });
})

const pagination =() => {
  if ($('.pagination.d-none').length){
    var already = []
    window.addEventListener('scroll', function(){
      var url = $('.pagination a[rel="next"]').attr('href')
      if (url && $(window).scrollTop() > $(document).height() - $(window).height() - 200){
        if(!already.includes(url)) {
          $.getScript(url).fail(function(){
            var index = already.indexOf(url);
            if (index > -1) {
              already.splice(index, 1);
            }
          })
          already.push(url)
        }
      }
    })
  }
}
function setCookie(lng){
  $('#dropdownLng').text(lng == 'fr' ? 'FR' : 'EN');
  document.cookie = `lng=${lng}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
  window.location.reload();
}
// Get the element with id="defaultOpen" and click on it
if (screen.width > 992 && $("#defaultOpen").length) {
  document.getElementById("defaultOpen").click();
}

export {myFunction, mynewFunction, openSidebar, setCookie}
