const Calendar = (create = '' , destroy = '') => {
    var dates = document.querySelectorAll('.t-day');
    var first = false; 
    var startedNumber = -1; 
    var oldArray = [];
    var addEventListenerClick = () => {
        dates.forEach(function(element) {
           element.addEventListener("click", function(event) {
            if(!event.target.classList.contains('t-today')){
                if (!first && noalready(event.target)){
                    unSelected(event.target)
                }else{
                    selectedDate(event.target)
                }
            }
           });
        });
    }
    var noalready = (event) => {
       var classes = event.classList
       if (classes.contains('t-start') || classes.contains('t-end') || classes.contains('t-range'))  return true;
       return false;
    }
    var unSelected = (event) => {
        var classes = event.classList
        if(classes.contains('t-range') || classes.contains('t-start') || classes.contains('t-end')){
            classes.remove('t-range')
            classes.remove('t-start') 
            classes.remove("working-on");
            classes.remove('t-end')
            var left = selectedLeft(event)
            var right = selectedRight(event)
            var leftClasses = left ? left.classList : ''
            var rightClasses = right ? right.classList : ''

            if (leftClasses){
                if(leftClasses.contains('t-range') || leftClasses.contains('t-start')){
                    left.classList.remove('t-range')
                    left.classList.add('t-end')
                } 
            }
            if (rightClasses){
                if(rightClasses.contains('t-range') || rightClasses.contains('t-end')){
                    right.classList.remove('t-range')
                    right.classList.add('t-start')
                    right.classList.add('working-on')

                }
            }
        }
        destroyCalendar(event)
    }
    var destroyCalendar = (event) =>{
        $.ajax({
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').last().attr('content'))},
            url: destroy,
            data: {date: event.dataset.date},
            method: 'PATCH'
        })
    }
    addEventListenerClick()

    

    var mouseMoveHandler = function(event) {
        mouseEnter(event)
    };

    var addEventListenerMouseEnter = () => {
        if(first){
            dates.forEach(function(element) {
                element.addEventListener("mousemove", mouseMoveHandler);
            })
        }
    }

    var removeaddEventListenerMouseEnter = () => {
        dates.forEach(function(element) {
           element.removeEventListener("mousemove", mouseMoveHandler);
        })
    } 
    
    
    function mouseEnter(event, t){
        var target = event.target
        setmouseEnter(target)
    }
    function setmouseEnter(target) {
        var array = setArray(target)
        if (oldArray.length){
            var oldarray = setdifference(array)
            oldArray = array;
            removeOldSelect(oldarray) 
        }
        array.forEach(function(num,index){
            var select = selected(num)
            if (index < 1){
              select.classList.remove('t-range')
              select.classList.remove('t-end')
              select.classList.add('t-start')
              select.classList.add("working-on");
            }else if (array.length == (index + 1) ){
              select.classList.remove('t-range')
              select.classList.add('t-end')
              select.classList.remove('t-start')
              select.classList.remove("working-on");
            }else{
                if (select.classList.contains('t-range')){

                }else{
                    select.classList.add("t-range")
                }
                select.classList.remove('t-end')
                select.classList.remove('t-start')
                select.classList.remove("working-on");
            }
        })
    }
    var removeOldSelect = (oldarray) => {
        oldarray.forEach(function(num){
            var select = selected(num)
            select.classList.remove('t-end')
            select.classList.remove('t-start')
            select.classList.remove("working-on");
            select.classList.remove('t-range')
        })
    }
    var setdifference = (array) => {
        return oldArray.filter(x => !array.includes(x));
    }
    var setArray = (target) => {
        var targetIndex = parseInt(target.dataset.index)
        if (startedNumber < targetIndex){
            return range(startedNumber, targetIndex)
        }else{
            return range(targetIndex, startedNumber)
        }
    }
    var range = (start, stop, step = 1) => {
        var a = [start], b = start;
        while (b < stop) {
            a.push(b += step || 1);
        }
        return a;
    }
    function selectedDate(event){
        first = !first;
        var classes = event.classList;
        classes.remove('t-range');
        var left = selectedLeft(event);
        var right = selectedRight(event);
        if (first && !classes.contains('t-start')){
            oldArray = [parseInt(event.dataset.index)]
            if (alreadySelected(right) && alreadySelected(left)){
                if (!selectedRange(left) && !selectedRange(right)) classes.add("t-start","working-on");
                if (!selectedRange(left) && !selectedRange(right)) classes.add("t-end");
                first = !first
            }else{
                startedNumber = parseInt(event.dataset.index)
                if (!selectedRange(left) && !selectedRange(right)) classes.add("t-start","working-on");
                clickAddMouseEnter()
            }
            return '';
        }else if (!first && !classes.contains('t-end')){
            if (!selectedRange(left) && !selectedRange(right)){
                classes.add("t-end");
            }
        }
        removeclickAddMouseEnter()
        SendToBackEnd()
    }

    var clickAddMouseEnter = () => {
        addEventListenerMouseEnter();
    }
    var removeclickAddMouseEnter = () => {
        removeaddEventListenerMouseEnter()
    }

    var selectedLeft = (event) => {
        return selected((parseInt(event.dataset.index) - 1))
    }
    var selectedRight = (event) => {
       return selected((parseInt(event.dataset.index) + 1))
    }
    var selectedRange = (event) => {
        if (event.classList.contains('t-range')) return true;
        return false;
    }
    var selected = (num) => {
       return document.querySelector(`[data-index='${parseInt(num)}']`);
    }

    var alreadySelected = (event) => {
        if (event == null) return true;
        var EventclassList = event.classList;
        if (EventclassList.contains('t-disabled')){
            return true;
        }
        if (CheckHaveClass(EventclassList)) return true;
    }

    var CheckHaveClass = (selectedClass) => {
        if (selectedClass.contains('t-start') || selectedClass.contains('t-end')) {
            return true
        }else{
            return false
        }
    }

    var SendToBackEnd = () => {
        var selectedDate = mergeArray()
        $.ajax({
            beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').last().attr('content'))},
            url: create,
            data: selectedDate,
            method: 'PATCH'
        })
    }
    var mergeArray = () => {
        var start = document.querySelector('.t-start.working-on');
        var dates = {}
        if(start.classList.contains('t-end')){
            var last = start
        }else{
            var last = getNextElement(start, 't-end')
            console.log(last)
        }
        start.classList.remove('working-on')
        dates['start_date'] = getDate(start)
        dates['end_date'] = getDate(last)
        console.log(dates)
        return dates 
    }
    
    var getDate = (ele) =>{
        if(ele && ele.dataset.date){ 
            return ele.dataset.date
        }
    }

    function getNextElement(elem, className) {
        var next = elem.nextElementSibling; // set next element to "nextElementSibling" relative to passed element.
        var last = next
        while (next && !next.classList.contains(className)) { // check for existence and class
            next = next.nextElementSibling; // if it exists, but the class does not, move to the next element and repeat.
            if (!next) next = last.parentElement.nextElementSibling.firstElementChild
            last = next
        }
        return next; // return whatever was found, or null
    }

}

export default Calendar;