function initService() {
	var autocomplete1 = new window.google.maps.places.Autocomplete(
		document.getElementById('address'), { types: ["geocode"] }
	);
	autocomplete1.setFields(['address_component', 'geometry']); 
	var already = false;
	$('#address').on('keyup keypress click', function(){
		if(('.pac-container.pac-logo').length && !already){
			already = true;
			addFuction() 
		}
		if(!$('.address-auto').length){
			addrow()
		}
	})
}
function addrow(){
	$('.pac-container.pac-logo').append('<div onclick="addFuction()" class="pac-item address-auto" id="address-auto"><span class="pac-icon pac-icon-marker"></span><span><span class="pac-matched">Point de location : Vitry-sur-Seine (Recommandé)</span></div>')
}

function addFuction(){
	$(document).on('mousedown', '#address-auto',function() {
		console.log("clicked!"); 
		$('#address').val('Point de location : Vitry-sur-seine')
  });
}
export default initService
