function initMap() {
  if(document.getElementById("map")){
    var lat = document.querySelector('input[name="car_address[latitude]"]').value
    var lon = document.querySelector('input[name="car_address[longitude]"]').value
    var center = { lat: lat ? parseFloat(lat) : 40.749933, lng: lon ? parseFloat(lon) : -73.98633 }
    console.log(center)
    const map = new google.maps.Map(document.getElementById("map"), {
      center: center,
      zoom: 17,
      mapTypeControl: false,
    });
    const card = document.getElementById("pac-card");
    const input = document.getElementById("pac-input");
    const options = {
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);

    const autocomplete = new google.maps.places.Autocomplete(input, options);

    autocomplete.bindTo("bounds", map);

    const marker = new google.maps.Marker({
      map,
      anchorPoint: new google.maps.Point(0, -29),
      position: lat ? center : {}
    });

    autocomplete.addListener("place_changed", () => {
      marker.setVisible(false);
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.geometry.location) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      marker.setPosition(place.geometry.location);
      marker.setVisible(true);
      document.querySelector('input[name="car_address[latitude]"]').value = place.geometry.location.lat()
      document.querySelector('input[name="car_address[longitude]"]').value = place.geometry.location.lng()
      document.querySelector('form.maps').submit()
    });
    autocomplete.setTypes(["address"]);
  }
  
}



export default initMap;
