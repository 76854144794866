const MobileScrollSelect = function (eleID, inputID, showText = '', boxDiv = false) {
    const ele = document.getElementById(eleID);
    ele.style.cursor = 'grab';
    var input = document.getElementById(inputID);
    if(showText) var showDiv = document.getElementById(showText);
    var timer = null;
    const setScroller = function(e){
      var old = document.querySelector(`#${eleID} #selected`)
      if(old){
        old.classList.remove('selected')
        old.classList.add('infocus')
      }
      
      if(timer !== null) {
        clearTimeout(timer);        
      }
      timer = setTimeout(function() {
        Scrolling(e)
      }, 50);
      setSelecter()
    }   

    ele.addEventListener('scroll', setScroller);
    var Scrolling = function (e = '' , b = '') {
      setSelecter()
      ele.scroll({
        behavior: 'smooth',
        top: Math.round((e ? e.target.scrollTop : b) / 42) * 42,
      })
    }
    
    var setSelected = (element) => {
      var selected = document.querySelector(`#${eleID} #selected`);
      if (selected){
        if (input) {
          input.value = element.getAttribute('data-value') 
          input.dispatchEvent(new Event('change', { 'bubbles': true }));
        }
        
        if(showText && showDiv) showDiv.textContent = selected.textContent
        selected.classList.remove('selected');
        selected.classList.add('outfocus')
        selected.id = '';  
      }
      var olds = ele.querySelectorAll('.infocus')
      olds.forEach(function(element){
        element.classList.remove('infocus')
        element.classList.add('outfocus')
      })
      if(element.nextElementSibling){
        element.nextElementSibling.classList.remove('outfocus')
        element.nextElementSibling.classList.add('infocus')
      }
      if(element.previousElementSibling){
        element.previousElementSibling.classList.remove('outfocus')
        element.previousElementSibling.classList.add('infocus')
      }   
      element.classList.add('selected');
      element.classList.remove('infocus');
      element.classList.remove('outfocus');

      element.id = 'selected';
    }

    const setSelecter = function() {
      var offset = ele.getClientRects()
      var offset = offset[0]
      if(offset){
        var selected = document.elementFromPoint(offset.x+(offset.width/2), (offset.y+85))
      }
      if(selected){
        if (selected.classList.contains('t-disabled')){
          if (input && input.value == selected.getAttribute('data-value')) {
            input.value = ''
            input.dispatchEvent(new Event('change', { 'bubbles': true }));
          }
        }else{
          setSelected(selected)
        }
      }
    }
    if(document.querySelector(`#${eleID} #selected`)){
      Scrolling('',document.querySelector(`#${eleID} #selected`).offsetTop - 84)
    }

    function addEvent(element, event_name, func) {
      if (element.addEventListener) {
        element.removeEventListener(event_name, func, true); 
        element.addEventListener(event_name, func, false); 
      }
    }
    function clicked(e){
      if (e.target.offsetTop > 85){
        Scrolling('',e.target.offsetTop - 84)
      }else{
        Scrolling('',e.target.offsetTop - 84)
        if (e.target.classList.contains('t-disabled')){
          if (input && input.value == e.target.getAttribute('data-value')) {
            input.value = ''
            input.dispatchEvent(new Event('change', { 'bubbles': true }));
          }
        }else{
          if (input) {
            input.value = e.target.getAttribute('data-value') 
            input.dispatchEvent(new Event('change', { 'bubbles': true }));
          }
          var nextElement = e.target.nextElementSibling
          e.target.classList.add('selected');
          if(nextElement){
            nextElement.classList.remove('outfocus')
            nextElement.classList.add('infocus')
          }
        }
      }
    }
    // add clickevent
    var childNodes = ele.childNodes;
    for (var i = 0; i < childNodes.length; i++) {
        addEvent(childNodes[i], "click", clicked);
    }
    
}

export default MobileScrollSelect;
